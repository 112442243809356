import autoComplete from "@tarekraafat/autocomplete.js";

/**
 * Make an element sticky
 */
const CSS_CLASS_ACTIVE = 'is-fixed';
const StickyElement = {

    element: null,

    position: 0,

    addEvents: function() {
        window.addEventListener('scroll', this.onScroll.bind(this));
    },

    init: function(element) {
        this.element = element;
        this.addEvents();
        this.position = element.offsetTop ;
        this.onScroll();
    },

    aboveScroll: function() {
        return this.position < window.scrollY;
    },

    onScroll: function(event) {
    
        if (this.aboveScroll()) {
            this.setFixed();
        } else {
            this.setStatic();
        }
    },

    setFixed: function() {
        this.element.classList.add(CSS_CLASS_ACTIVE);
        // not needed if added with CSS Class
        this.element.style.position = 'fixed';
        this.element.style.top = 0;
        this.element.style.marginTop = '0';
    },

    setStatic: function() {
        this.element.classList.remove(CSS_CLASS_ACTIVE);
        // not needed if added with CSS Class
        this.element.style.position = 'static';
        this.element.style.top = 'auto';
        // this.element.style.marginTop = '5px';
    }
}

function enableToggleBranding() {
    let check = document.querySelector('input#whitelabel-opt'),
        branding = document.querySelector('.fv-branding');
    
    check.addEventListener('click', function() {
        branding.classList.toggle('d-none')
    })
}

function enableToggleSection() {
    let sectionToggler = document.querySelectorAll('button.btn-hide-section');

    sectionToggler.forEach(function(toggler) {
        toggler.addEventListener('click', function(e) {
            let target = e.currentTarget,
                targetId = target.getAttribute('target'),
                section = document.getElementById(targetId);

            let isOff = target.childNodes[0],
                isOn = target.childNodes[1];

            // if is visible already we can switch to off
            if( isOff.classList.contains('d-none') ) {

                let input = document.querySelector('input[name="'+targetId+'-visibility"]');
                input.value = 1;

                isOn.classList.add('d-none');
                isOff.classList.remove('d-none');
            
            // otherwise, bring it back and switch the icon
            } else {
                let input = document.querySelector('input[name="'+targetId+'-visibility"]');
                input.value = 0;

                isOff.classList.add('d-none');
                isOn.classList.remove('d-none');
                isOn.classList.add('d-block');
            }

            section.classList.toggle('hide-on-print');
            section.classList.toggle('section-hide-on-print');

        })
    })
}

function enableFeaturedImages()
{
    let btns = document.querySelectorAll('button.choose-featured'),
        editableImages = document.querySelector('textarea[name="featured-images'),
        galleryWrapper = document.querySelector('.gallery-screen'),
        galleryContainer = galleryWrapper.querySelector('.row'),
        galleryImages = document.querySelectorAll('section[source]'),
        editableImagesObj = {},
        setter;
    
    btns.forEach(function(btn){
        
        btn.addEventListener('click', function() {
            document.body.style.overflow = 'hidden';
            
            let featuredTarget = btn.getAttribute('featured-target'),
                featuredTargetEl = document.querySelector('#' + featuredTarget);

            galleryImages.forEach(function(gimg) {
                // get the full image source
                let source = gimg.getAttribute('source');

                // create the col grid
                let imgEl = document.createElement('div');
                    imgEl.classList.add('col-lg-3')

                // create inner element that holds the image as background
                let imgElInner = document.createElement('div');
                    imgElInner.classList.add('thumb', 'rounded', 'd-flex', 'w-100');
                    imgElInner.style.backgroundImage = 'url('+source+')'

                // create the featured setter button
                setter = document.createElement('button');
                    setter.classList.add('btn', 'btn-dark', 'btn-sm', 'align-self-center', 'mx-auto');
                    setter.textContent = 'Set featured image';

                setter.addEventListener('click', function() {
                    featuredTargetEl.style.backgroundImage = 'url('+source+')';
                    
                    // once set, we can grab the image and store as json for post submission
                    // retrieve the section name 'primary' | 'secondary' so it can be used 
                    // as key identifier when adding/updating the featured images object 
                    let sectionId = featuredTarget.split('-');
                        sectionId = sectionId[0];

                    editableImagesObj[sectionId] = source;
                    editableImages.value = JSON.stringify(editableImagesObj);
                    featuredTargetEl.setAttribute('has-image', 'true');
                })

                imgElInner.appendChild(setter);
                imgEl.appendChild(imgElInner);
                galleryContainer.appendChild(imgEl);
            })

            galleryWrapper.classList.toggle('d-none');
        });
    })


    // gallery wrapper closer
    let btnCloser = document.querySelector('button.closer');
    btnCloser.addEventListener('click', function() {
        galleryWrapper.classList.toggle('d-none');
        galleryWrapper.querySelector('.row').innerHTML = '';
        document.body.style.overflow = '';
    })
}

let editableAreas = document.querySelectorAll('*[contenteditable]'),
    editableImages = document.querySelector('textarea[name="featured-images'),
    textareaPrimary = document.querySelector('textarea[name="primary-section"]'),
    textareaSecondary = document.querySelector('textarea[name="secondary-section"]'),
    textareaAbout = document.querySelector('textarea[name="about-section"]'),
    textareaGallery = document.querySelector('textarea[name="gallery-section"]'),
    // 
    interiorFeatures = document.querySelector('textarea[name="interior-section"]'),
    exteriorFeatures = document.querySelector('textarea[name="exterior-section"]'),
    includedFeatures = document.querySelector('textarea[name="included-section"]'),
    extraFeatures = document.querySelector('textarea[name="extra-section"]');

let primaryCollection = {},
    secondaryCollection = {},
    aboutCollection = {},
    galleryCollection = {},
    mapCollection = {},
    interiorCollection = {},
    exteriorCollection = {},
    includedCollection = {},
    extraCollection = {};

function updateHiddenContents()
{
    textareaPrimary.value = JSON.stringify(primaryCollection);
    textareaSecondary.value = JSON.stringify(secondaryCollection);
    textareaAbout.value = JSON.stringify(aboutCollection);
    textareaGallery.value = JSON.stringify(galleryCollection);
    
    interiorFeatures.value = Object.keys(interiorCollection).length === 0 ? null : JSON.stringify(interiorCollection);
    exteriorFeatures.value = Object.keys(exteriorCollection).length === 0 ? null : JSON.stringify(exteriorCollection);
    includedFeatures.value = Object.keys(includedCollection).length === 0 ? null : JSON.stringify(includedCollection);
    extraFeatures.value = Object.keys(extraCollection).length === 0 ? null : JSON.stringify(extraCollection);
}

function parseContentEditables(area)
{
    let type = area.getAttribute('editable-area');
        type = type.split('-');
    
    let key = type[1];
        type = type[0];

    if( type === 'primary' ) {
        primaryCollection[key] = area.textContent;
    } else if( type === 'secondary' ) {
        secondaryCollection[key] = area.textContent
    } else if( type === 'about' ) {
        aboutCollection[key] = area.textContent
    } else if( type === 'interior' ) {
        interiorCollection[key] = area.textContent
    } else if( type === 'outdoor' ) {
        exteriorCollection[key] = area.textContent
    } else if ( type === 'included' ) {
        includedCollection[key] = area.textContent
    } else if ( type === 'extra' ) {
        extraCollection[key] = area.textContent
    }
}

function enableAutocomplete()
{
    const AutoCompleteSearch = new autoComplete({
        data: {
          src: async () => {
                // Creating or getting search data from Indexed Database via user's browser
                const source = await fetch('/api/fetch');
                // Format data into JSON
                const data = await source.json();
                // Return Fetched data
                return data;
        },
        key: ["title"],
            results: (list) => {
                return list;
            },
            cache: false
        },
        sort: (a, b) => {                    // Sort rendered results ascendingly | (Optional)
            if (a.match < b.match) return -1;
            if (a.match > b.match) return 1;
            return 0;
        },
        selector: "#searchbar--input",
        // observer: true,
        threshold: 3,
        debounce: 170,
        searchEngine: "strict",
        resultsList: {
            container: source => {
                source.setAttribute("id", "list");
            },
            destination: "#searchbar--input",
            position: "afterend",
            element: "ul"
        },
        maxResults: 9999,
        highlight: false,
        resultItem: {
            content: (data, source) => {
                let title = '<span class="search--item--title">' + data.value.title + '</span>',
                    thumb = '<img class="float-start rounded me-3" src="' + data.value.media_featured + '" height="55px" width="55px" style="background-color:#EEE">';

                source.innerHTML = thumb + title;
            },
            element: "li"
        },
        noResults: (dataFeedback, generateList) => {
            // Generate autoComplete List
            generateList(AutoCompleteSearch, dataFeedback, dataFeedback.results);
            // No Results List Item
            const result = document.createElement("li");
            result.setAttribute("class", "no_result d-block");
            result.setAttribute("tabindex", "1");
            result.innerHTML = `<span class="font-weight-normal">Couldn't find anything related to <code>"${dataFeedback.query}"</code></span>`;

            document.querySelector('#searchbar--area ul').appendChild(result);
        },
        onSelection: async (feedback) => {
            document.querySelector("#searchbar--input").value = '';

            let destinationId = getDestinationById(feedback.selection.value.destination_id);
            window.location.href = '/new?id=' + feedback.selection.value.property_id + '&destination=' + destinationId;
        }
    });
}

function getDestinationById(id)
{
    switch(id) {
        case 6:
            return 'italy';
        case 9:
            return 'greece';
        case 8:
            return 'spain';
        case 10:
            return 'france';
        case 16:
            return 'cyprus';
        case 11:
            return 'bali';
        case 12:
            return 'thailand';
        case 13:
            return 'mexico';
        case 14:
            return 'caribbean';
        case 173:
            return 'top';
        default:
            return null;
    }
}

function enableInlineEditing()
{
    editableAreas.forEach(function(area) {

        area.addEventListener('keydown', function(event) {

            if (event.keyCode !== 13)
                return true;

            event.preventDefault();

            var docFragment = document.createDocumentFragment();

            //add a new line
            var newEle = document.createTextNode('\n');
            docFragment.appendChild(newEle);

            //add the br, or p, or something else
            newEle = document.createElement('br');
            docFragment.appendChild(newEle);

            //make the br replace selection
            var range = window.getSelection().getRangeAt(0);
            range.deleteContents();
            range.insertNode(docFragment);

            //create a new range
            range = document.createRange();
            range.setStartAfter(newEle);
            range.collapse(true);

            //make the cursor there
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            return false;
        })

        parseContentEditables(area);
    });

    let galleryimages = document.querySelectorAll('.gallery-img');    
    galleryimages.forEach(function(gimg, k) {
        galleryCollection['img_' + k] = gimg.getAttribute('source');
    })

    updateHiddenContents();
}


/**
 * Retrieve current screen URL
 * 
 * @param  {String} screen
 * 
 * @return {Boolean}
 */
export const getScreen = () =>
{
    return document.body.getAttribute('current-screen');
}

export function init()
{

    if( getScreen() === 'presentation-new' || getScreen() === 'presentation-edit' ) {
        StickyElement.init(document.querySelector('.presentation-navbar'));

        // Enable support for adding or removing the branding.
        // enableToggleBranding();
        // Enable support for toggle sections
        enableToggleSection();
        // Enable gallery selection for primary & secondary sections
        enableFeaturedImages();
        // Enable inline editing
        enableInlineEditing();
    }

    if( getScreen() === 'dashboard' ) {
        enableAutocomplete();
    }

    let printAction = document.querySelector('button[js="btn-print-presentation"]');
    let splash = document.querySelector('.splash-screen-print');

    Camino.contentEditable({
        textOnly: false,
        onKeypress: (el, caret) => {
            // console.log(el.get().textContent)
        },
        onKeyup: (el, caret) => {
            parseContentEditables(el.get());
            updateHiddenContents();
        }
    });

    if( printAction ) {
        printAction.addEventListener('click', function() {
            
            splash.classList.toggle('d-none');
            splash.querySelector('svg').classList.remove('d-none')

            window.scrollTo(0,document.body.scrollHeight);
            
            let iframe = document.querySelector('.fit-object-map iframe');
            document.body.style.cursor = 'progress';

            if( iframe ) {
                // set a timeout for window printing to be sure 
                // we are going to catch also the google maps iframe.
                setTimeout(function(){
                    splash.querySelector('svg').classList.toggle('d-none')
                    window.print();
                }, 2000);

            // If the property has no map, then just reveal the print
            } else {
                window.print();
            }
        })

        window.onafterprint = function(){
            splash.classList.toggle('d-none');
            window.scrollTo(0,0);
            document.body.style.cursor = 'inherit';
            console.log("Printing completed...");
        }
    }
}